<template>
  <div>
    <!--搜索框-->
    <van-cell-group>
      <van-field
        v-model="nickName"
        placeholder="客户名称"
        :border="false"
        bind:change="onChange"
      />
      <van-radio-group
        v-model="isAuth"
        direction="horizontal"
        style="margin-left: 10%"
        @change="chooseIsAuth"
      >
        <van-radio :name="1">已认证</van-radio>
        <van-radio :name="2" style="margin-left: 30%">未认证</van-radio>
      </van-radio-group>
      <!--选择客服-->
      <customer @cusValue="chooseCus" />
      <van-button type="info" size="large" @click="onload">查询</van-button>
    </van-cell-group>
    <!--加载状态-->
    <div class="loading" v-if="loading">
      <van-loading color="#1989fa" :vertical="true">加载中...</van-loading>
    </div>
    <!--数据展示-->
    <div v-else>
      <el-table
        :data="
          tableData.slice(
            (currentPage - 1) * pageCount,
            currentPage * pageCount
          )
        "
        max-height="700"
        border
        style="width: 100%"
      >
        <el-table-column prop="nickname" label="客户" width="100" />
        <el-table-column prop="isAuth" label="认证状态" width="100" />
        <el-table-column prop="total_price" label="团购订单" width="100" />
        <el-table-column prop="recharge_money" label="会员订单" width="100" />
        <el-table-column fixed="right" prop="name" label="客服" width="100" />
      </el-table>
      <el-pagination
        style="footer"
        small
        layout="prev, pager, next"
        :total="tableData.length"
        :page-count="pageCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [], //初始化数据
      loading: true, //加载状态
      pageCount: 10, //展示条数
      currentPage: 1, //当前页
      isAuth: 1, //认证情况
      nickName: "",
      cusValue: 0 //客服id
    };
  },
  methods: {
    //初始化
    onload() {
      this.loading = true;
      this.tableData = [];
      this.cusValue = this.cusValue === 0 ? "" : this.cusValue;
      this.$axios
        .get(
          `${this.$base}/fission/user/queryPrice?nickname=${this.nickName}&isAuth=${this.isAuth}&customer=${this.cusValue}`
        )
        .then((res) => {
          res.forEach((item) => {
            item.isAuth = item.isAuth === 1 ? "未认证" : "已认证";
            this.tableData.push(item);
          });
        });
      this.loading = false;
    },
    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    //客服组件赋值
    chooseCus(val) {
      this.cusValue = val;
    },
    //选择认证情况
    chooseIsAuth(name) {
      this.isAuth = name;
    }
  },
  mounted() {
    this.onload();
  }
};
</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
